const cookieNotice = () => {
    function getCookie(name) {
        const matches = document.cookie.match(new RegExp(
            `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    function setCookie(name, value, options = {}) {
        options = {
            path: '/',
        };
        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }
        let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
        for (const optionKey in options) {
            updatedCookie += `; ${optionKey}`;
            const optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += `=${optionValue}`;
            }
        }
        document.cookie = updatedCookie;
    }

    const cookieNotice = getCookie('cookie-notice');

    const cookieNoticeBlock = document.querySelector('.cookie-notice');
    if (cookieNotice) {
        cookieNoticeBlock.classList.remove('is-visible');
    } else {
        const cookieButton = document.querySelector('.js--cookie-ok');
        cookieNoticeBlock.classList.add('is-visible');
        cookieButton.addEventListener('click', () => {
            setCookie('cookie-notice', '1', { secure: true, 'max-age': 31536000 });
            cookieNoticeBlock.classList.remove('is-visible');
        });
    }
};

export default cookieNotice;
