import Swiper, {
    Navigation, Pagination, EffectFade, Controller, Autoplay,
} from 'swiper';

Swiper.use([Navigation, Pagination, EffectFade, Controller, Autoplay]);

const pricesSlider = () => {
    const swiperSlider = new Swiper('.js--prices-slider', {
        loop: false,
        slidesPerView: 2,
        watchOverflow: true,
        fadeEffect: {
            crossFade: true,
        },
        autoHeight: true,
        // autoplay: {
        //     delay: 5000,
        // },
        pagination: {
            el: '.js--prices-slider-nav .swiper-control__fraction',
            type: 'fraction',
            currentClass: 'swiper-control__fraction-current h3',
            totalClass: 'swiper-control__fraction-total h4',
            disabledClass: 'is-disabled',
            hiddenClass: 'is-hidden',
            lockClass: 'is-lock',
            formatFractionCurrent(number) {
                if (number < 10) {
                    number = `0${number}`;
                }
                return number;
            },
            formatFractionTotal(number) {
                if (number < 10) {
                    number = `0${number}`;
                }
                return number;
            },
        },
        navigation: {
            nextEl: '.js--prices-slider-nav .swiper-control__next',
            prevEl: '.js--prices-slider-nav .swiper-control__prev',
            disabledClass: 'is-disabled',
            hiddenClass: 'is-hidden',
            lockClass: 'is-lock',
        },
        spaceBetween: 50,
        breakpoints: {
            // when window width is >= 320px
            240: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            800: {
                slidesPerView: 2,
                spaceBetween: 40
            },
            1024: {
                slidesPerView: 2,
                spaceBetween: 50
            }
        }
    });
};

export default pricesSlider;
