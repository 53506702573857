const scrollTo = () => {
    const selector = document.querySelectorAll(".js--scroll-to");

    const scrollToTarget = (selector) => {
        const targetElement = document.querySelector(
            `.js--scroll-target-${selector}`
        );
        const targetHeight = targetElement.offsetTop;
        window.scroll({
            top: targetHeight,
            left: 0,
            behavior: "smooth",
        });
    };

    for (let i = 0; i < selector.length; i++) {
        selector[i].addEventListener("click", function () {
            const targetDataset = this.dataset.scrollTo;
            scrollToTarget(targetDataset);
        });
    }
};

export default scrollTo;
