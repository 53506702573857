import Swiper, {
    Navigation, Pagination, EffectFade, Controller, Autoplay,
} from 'swiper';

Swiper.use([Navigation, Pagination, EffectFade, Controller, Autoplay]);

const mainSlider = () => {
    const swiperSlider = new Swiper('.js--main-slider', {
        loop: false,
        slidesPerView: 1,
        watchOverflow: true,
        fadeEffect: {
            crossFade: true,
        },
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.js--main-slider-nav .swiper-control__fraction',
            type: 'fraction',
            currentClass: 'swiper-control__fraction-current h3',
            totalClass: 'swiper-control__fraction-total h4',
            disabledClass: 'is-disabled',
            hiddenClass: 'is-hidden',
            lockClass: 'is-lock',
            formatFractionCurrent(number) {
                if (number < 10) {
                    number = `0${number}`;
                }
                return number;
            },
            formatFractionTotal(number) {
                if (number < 10) {
                    number = `0${number}`;
                }
                return number;
            },
        },
        navigation: {
            nextEl: '.js--main-slider-nav .swiper-control__next',
            prevEl: '.js--main-slider-nav .swiper-control__prev',
            disabledClass: 'is-disabled',
            hiddenClass: 'is-hidden',
            lockClass: 'is-lock',
        },
        spaceBetween: 40,
    });
};

export default mainSlider;
