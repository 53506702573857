import lazyLoad from './components/lazyload';
import mainSlider from './components/main-slider';
//import variantsSlider from './components/variants-slider';
import pricesSlider from './components/prices-slider';
import modals from './components/modals';
import setPhoneMask from './components/input-mask';
import cookieNotice from './components/cookie';
import scrollTo from './components/scroll-to';

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.js--lazyload')) {
        lazyLoad();
    }
    if (document.querySelector('.js--main-slider')) {
        mainSlider();
    }
    // if (document.querySelector('.js--variants-slider')) {
    //     variantsSlider();
    // }
    if (document.querySelector('.js--prices-slider')) {
        pricesSlider();
    }
    if (document.querySelector('.modal')) {
        modals();
    }
    if (document.querySelector('.js--phone-mask')) {
        setPhoneMask();
    }
    if (document.querySelector('.js--cookie-notice')) {
        cookieNotice();
    }
    if (document.querySelector('.modal')) {
        modals();
    }
    if (document.querySelector('.js--scroll-to')) {
        scrollTo();
    }
});
